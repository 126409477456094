import React, { useCallback, useEffect, useState } from 'react'

type PropsTypes = {
  filename: string
}

type TextAttributesProps = {
  date: string
  title: string
}

type TextProps = {
  attributes: TextAttributesProps
  html: string
}

export const MarkdownReader = ({ filename }: PropsTypes) => {
  const [text, setText] = useState<TextProps>({
    attributes: { title: '', date: '' },
    html: '',
  })

  const getInitialData = useCallback(async () => {
    const data = await import(`../content/${filename}.md`)
    setText(data.default)
  }, [filename])

  useEffect(() => {
    const loadFile = async () => {
      await getInitialData()
    }
    loadFile()
  }, [getInitialData])

  return <div dangerouslySetInnerHTML={{ __html: text.html }} />
}
