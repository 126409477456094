import * as React from 'react'
import { Layout } from '../components/Layout'
import { MarkdownReader } from '../components/Text'
import type { HeadFC } from 'gatsby'
import { SEO } from '../components/SEO'

const IndexPage = () => {
  return (
    <Layout>
      <div className='text-box presentation-text'>
        <MarkdownReader filename="home" />
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <SEO title="Lakshmi" pathname="/"></SEO>
